import { FormControl, Validators, FormGroup } from '@angular/forms';

export class FormBaseClass {

    createForm(fieldList) {
        const formGroup: any = {};
        Object.keys(fieldList).forEach((key) => {
            if (fieldList[key].required) {
                formGroup[key] = new FormControl(null, Validators.required);
            } else {
                formGroup[key] = new FormControl(null);
            }
        });
        return new FormGroup(formGroup);
    }

}
